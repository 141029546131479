import type { GetServerSideProps } from 'next';

import { FALLBACK_LNG } from '@app/_internal/i18n/settings';
import { auth } from '@app/_internal/utils/auth';

import { LoginPage } from '@/feature-auth';
import { withAuthLayout } from '@/layouts/AuthSharedLayout';
import { AppRoutes } from '@/routes';

function Page() {
  return <LoginPage />;
}

// NOTE: start evaluating from the 3rd character (ignore prefixed locales)
// example: /en/d or /ko/d -> /d
const IGNORE_LOCALE_PATHNAME = 3;

function isSignInPage(resolvedUrl: string) {
  if (
    resolvedUrl.startsWith(AppRoutes.SIGNIN) ||
    resolvedUrl.startsWith(AppRoutes.SIGNIN, IGNORE_LOCALE_PATHNAME)
  ) {
    return true;
  }

  try {
    const url = new URL(resolvedUrl);
    return (
      url.pathname.startsWith(AppRoutes.SIGNIN) ||
      url.pathname.startsWith(AppRoutes.SIGNIN, IGNORE_LOCALE_PATHNAME)
    );
  } catch (error) {
    return false;
  }
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { params, req, res, resolvedUrl, query } = context;

  const lang = params?.lang || FALLBACK_LNG;

  const session = await auth(req, res);

  // NOTE: session is still valid / successfully rotated
  if (session && session.error == null) {
    const indexPathname = `/${lang}${AppRoutes.INDEX}`;

    let callbackUrl = resolvedUrl;

    // NOTE: handle callbackUrl query param
    if (Array.isArray(query?.callbackUrl) && query?.callbackUrl.length) {
      callbackUrl = query.callbackUrl[query.callbackUrl.length - 1]; // get the last item
    } else if (typeof query?.callbackUrl === 'string') {
      callbackUrl = query.callbackUrl;
    }

    // NOTE: if signin page then use indexPathname instead, this prevents infinite redirect loop
    callbackUrl = isSignInPage(callbackUrl) ? indexPathname : callbackUrl;

    return {
      redirect: {
        destination: callbackUrl,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default withAuthLayout(Page);
